'use strict'

###*
 # @ngdoc object
 # @name mundoAuthentication.controller:MundoAuthenticationCtrl

 # @description

###
class MundoAuthenticationCtrl
  ### @ngInject ###
  constructor: (
    $state
    OAuth
    OAuthToken
    $rootScope
    $timeout
    $log
    UserPermissions
    $translate
    mundoConfiguration
    $location
  ) ->
    @ctrlName = 'MundoAuthenticationCtrl'

    @entity =
      username: null
      password: null

    # @rememberMe = false
    @errorMessage = null
    @loading = false

    @SAML2SSOEnabled = mundoConfiguration.saml2? and mundoConfiguration.saml2.identity_provider_entity_id?

    @loginCheck = ->
      @loading = true

      OAuth
        .getAccessToken(@entity)
        .then (result) =>
          @errorMessage = null

          # Clear user & pass
          @entity.username = null
          @entity.password = null

          $rootScope.$emit 'mundo:authentication:user:reload'

          listeners = []
          listener = $rootScope.$on 'mundo:authentication:user:permissions:loaded', (event, data) =>
            (x() for x in listeners)
            @loading = false

            if UserPermissions.check 'access lpa dispatching frontend'
              $state.go 'dispatching'
            else
              @logout()
              @errorMessage = $translate.instant('app.errors.login.permissions')
          listeners.push listener

          listener = $rootScope.$on 'mundo:authentication:error', (event, data) =>
            (x() for x in listeners)
            @loading = false

            @logout()
            @errorMessage = $translate.instant('app.errors.login.permissions')
          listeners.push listener

        , (result) =>
          @errorMessage = result.data.error_description
          @loading = false

    @logout = ->
      @loading = true

      OAuth
        .revokeToken()
        .then () =>
          @loading = false
          $rootScope.$broadcast 'mundo:authentication:logout'
          SAMLSSOEnabled = mundoConfiguration.saml2? and mundoConfiguration.saml2.identity_provider_entity_id?
          if SAMLSSOEnabled
            @performLogoutSAML2SSO()
          else
            $state.go 'authentication.init'

    @parseRedirectPayload = (payload) ->
      parsed = window.atob payload
      parsed = JSON.parse parsed

      tokenData = parsed.token
      OAuthToken.setToken tokenData

      redirectUrl = parsed.redirect
      window.location = redirectUrl

    @performSAML2SSO = () ->
      baseUrl = mundoConfiguration.oauth.baseUrl
      redirectUrl = $state.href 'dispatching', {}, {absolute: true}
      oauthClientId = mundoConfiguration.oauth.clientId
      tenantId = mundoConfiguration.tenant.id
      idp = mundoConfiguration.saml2.identity_provider_entity_id

      targetBaseUrl = window.btoa($location.absUrl().replace($location.url(), ''))
      redirectUrl = window.btoa redirectUrl
      oauthClientId = window.btoa oauthClientId
      tenantId = window.btoa tenantId

      url = "#{baseUrl}/saml/login_redirect/#{targetBaseUrl}/#{oauthClientId}/#{redirectUrl}?idp=#{idp}"
      window.location = url

    @performLogoutSAML2SSO = () ->
      baseUrl = mundoConfiguration.oauth.baseUrl
      redirectUrl = $state.href 'authentication.init', {}, {absolute: true}
      idp = window.btoa mundoConfiguration.saml2.identity_provider_entity_id

      redirectUrl = window.btoa redirectUrl

      url = "#{baseUrl}/saml/logout/#{idp}/#{redirectUrl}"

      window.location = url

    ## Method for redirecting to external logins if needed
    @loginRedirection = () ->
      SAMLSSOEnabled = mundoConfiguration.saml2? and mundoConfiguration.saml2.identity_provider_entity_id?
      SAMLSSOAutomaticRedirect = mundoConfiguration.saml2? and mundoConfiguration.saml2.automatic_redirect? \
      and mundoConfiguration.saml2.automatic_redirect
      if SAMLSSOEnabled and SAMLSSOAutomaticRedirect
        @performSAML2SSO()
      else
        $state.go 'authentication.login'

angular
  .module('mundoAuthentication')
  .controller 'MundoAuthenticationCtrl', MundoAuthenticationCtrl
